<template>
  <div class="home p-d-flex p-jc-center">
      <img src="../assets/logo.gif" alt="Sistema Estribo" sizes="500" height="500"/>
  </div>
</template>

<script>

export default {
  data(){
    return{
     
    }
  }
}
</script>
